import React from 'react'
import { FaDiscord, FaTwitter, FaGithub} from 'react-icons/fa'
import { FooterContainer, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, ImageLogo } from './footerElements'
import { animateScroll as scroll } from 'react-scroll';
import logo from "../assets/images/logo.png";

const Footer = () => {

  const toggleHome = () => { scroll.scrollToTop(); }
  
  return (
    <FooterContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialIcons>
              <SocialIconLink href='https://discord.gg/R8rxsPpQ6d' target='_blank' arial-label='Discord'>
                <FaDiscord />
              </SocialIconLink>
              <SocialIconLink href='https://twitter.com/Dropnationale' target='_blank' arial-label='Twitter'>
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://github.com/Dropnation' target='_blank' arial-label='Github'>
                <FaGithub />
              </SocialIconLink>
            </SocialIcons>
            <WebsiteRights>© COPYRIGHT-{new Date().getFullYear()} DROPNATION. ALL RIGHTS RESERVED.</WebsiteRights>
            <SocialLogo>
              <ImageLogo onClick={toggleHome} src={logo} type='image/png' />
            </SocialLogo>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterContainer>
  )
}

export default Footer