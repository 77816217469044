import React from "react";
import {BrowserRouter, Routes , Route} from "react-router-dom";
import Claim from "./pages/claim";

function App() {

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Claim/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;