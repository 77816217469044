import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
        appName: "PulseDrop", // Required
        //rpc: "https://rpc.ankr.com/eth_goerli", // GOERLI TESTNET
        //chainId: 5, // GOERLI TETNET
        rpc: "https://rpc.pulsechain.com", // PULSECHAIN MAINNET
        chainId: 369, // Optional. It defaults to 1 if not provided
        appLogoUrl: "", // Optional. Application logo image URL. favicon is used if unspecified
        darkMode: true, // Optional. Use dark theme, defaults to false
      },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
        rpc: {
          //5: "https://rpc.ankr.com/eth_goerli" GOERLI TESTNET
          369: "https://rpc.pulsechain.com" // PULSECHAIN MAINNET
        },
      },
  }
};