import React, { useEffect, useState, useRef, memo } from 'react';
import './claim.css';
import '../App.css';
import * as s from "../styles/globalStyles";

import { MerkleTree } from "merkletreejs";
import { ethers } from "ethers";
import Web3Modal from 'web3modal';
import { providerOptions } from '../tools/providerOptions';
import { truncateAddress } from '../tools/utils';

import { Fireworks } from '@fireworks-js/react'
import Footer from './footer';
import distributor_abi from "../abi/distributor_abi.json"


// Web3Modal Constructor

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  theme: "dark",
  providerOptions, // required
});

function Claim() {

  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);  
  const [state, setState] = useState(false);
  const [isEligible, setIsEligible] = useState(null);
  const [airdropState, setAirdropState] = useState(null);
  const [memedropState, setMemedropState] = useState(null);
  const [renderedComponent, setRenderedComponent] = useState(null);
  const accountRef = useRef(null);
  const ownerProofPulseRef = useRef(null);
  const ownerProofDropRef = useRef(null);
  const [buttonText, setButtonText] = useState('CONNECT WALLET');
  const distributorAddress = "0xc2e703035468e8AD1cc2FF0d7456421Dc3a50DA4";

  const refreshState = () => {
    setIsConnected(false);
    setIsEligible(null);
    setButtonText("CONNECT WALLET")
  }

  const disconnectWallet = () => {
    web3Modal.clearCachedProvider();
    localStorage.clear();
    refreshState();
    setAccount();
    setProvider();
    setSigner();
    setButtonText('CONNECT WALLET');
    setIsLoading(false);
    accountRef.current = null;
    ownerProofPulseRef.current = null;
    ownerProofDropRef.current = null;
  };

  const handleAirdrop = () => {
    setState(false);
    setIsEligible(null);
    setAirdropState(null);
    setMemedropState(null);
  };
  const handleMemedrop = () => {
    setState(true);
    setIsEligible(null);
    setAirdropState(null);
    setMemedropState(null);
  };

  const verifyclaim = async () => {
    try {      
      const contractv = new ethers.Contract(distributorAddress, distributor_abi, signer); 
      if (state == false) {
        console.log(accountRef);
        const claimedUser = await contractv.claimedUser(accountRef.current);
        console.log("claimedUser", claimedUser);
        console.log("claimedUserObjectType:", typeof(claimedUser));
        setAirdropState(claimedUser);
        console.log("CurrentAirdropState:", airdropState);
      } else {
        console.log(accountRef);
        const claimedFarmer = await contractv.claimedFarmer(accountRef.current);
        console.log("claimedFarmer", claimedFarmer);
        console.log("claimedFarmerObjectType:", typeof(claimedFarmer));
        setMemedropState(claimedFarmer);
      }
    } catch (error) {
      console.log('User rejected the transaction');
    }    
  }
  
  // Connect Wallet

  const initConnection = async () => {

    try {

      const provider = await web3Modal.connect();
      const tempProvider = new ethers.providers.Web3Provider(provider);
      const accounts = await tempProvider.listAccounts();
      const signer = tempProvider.getSigner();

      const networkId = await tempProvider.provider.request({
        method: "net_version",
      });

      if (networkId !== 369) {
        await tempProvider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0x171" }],
        });
      }

      setProvider(tempProvider);
      if (accounts) setAccount(accounts[0]);
      if (accounts) accountRef.current = accounts[0];
      setSigner(signer);

      setIsConnected(true);      
      setButtonText('VERIFYING');
      setIsLoading(false);  
      
      console.log("Connected Account:", account)

    } catch (err) {

      if (
        typeof err !== "undefined" &&
        typeof err.message !== "undefined" &&
        err.message.includes("User Rejected")
        ) {
          console.log("User rejected the request");
          } else if (
          (typeof err === "string" || err instanceof String) &&
          console.log("Modal closed by user")
          ) {
          console.log("Modal closed by user");
      } else {
        console.log("Something went wrong.");
      }
      
    }
  }

  const claimAirdrop = async () => {
    console.log("PulseRefProof:", ownerProofPulseRef);
    try {
        const proof = ownerProofPulseRef.current;
        const contracta = new ethers.Contract(distributorAddress, distributor_abi, signer); 
        await contracta.claimAirdrop(proof, account); 
    } catch (error) {
      console.log('User rejected the transaction');
    }
  }

  const claimMemedrop = async () => {
    console.log("MemedropProof:", ownerProofDropRef);
    try {
      if (ownerProofDropRef.current.length > 0) {
        const proof = ownerProofDropRef.current;
        const contractm = new ethers.Contract(distributorAddress, distributor_abi, signer); 
        await contractm.claimMemedrop(proof);       
      }
    } catch (error) {
      console.log('User rejected the transaction');
    }
  }

  const { keccak256 } = ethers.utils;

  const verify = async () => {
    try {
      const response = await fetch('https://dropnation.wtf/assets/json/addressArray.json');
      //const response = await fetch('https://dropnation.wtf/assets/json/testArray.json');
      if (response.ok) {
        console.log("AirChecking...")
        const addressArray = await response.json();
        // const lowercaseAddresses = addressArray.map(addy => addy.toLowerCase());
        const leaves = addressArray.map(addr => keccak256(addr));
        const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        const rootHash = merkleTree.getRoot().toString('hex');
        // console.log(`addressArray Merkleroot: 0x${rootHash}`);
        ownerProofPulseRef.current = merkleTree.getHexProof(keccak256(accountRef.current));

        if (ownerProofPulseRef.current.length > 0) {
          setIsEligible(true);
        } else {
          setIsEligible(false);
        }

        console.log("Eligibility Stats:", isEligible);
        console.log("finalized");

      return;
      } else {
        console.error('Failed to fetch addressArray:', response.status, response.statusText);
      }
    } catch (error) {
      setButtonText('FAILED. REFRESH.')
      console.error('Error fetching addressArray:', error);
    }
  };

  const calldata = async () => {
    try {
      
      const response = await fetch('https://dropnation.wtf/assets/json/stakersArray.json');
      //const response = await fetch('https://dropnation.wtf/assets/json/teststakersArray.json');
      
      if (response.ok) {
        console.log('initialized...')
        const stakersArray = await response.json();
        const addresses = stakersArray.map((item) => item.address);
        const leaves = addresses.map((address) => keccak256(address));
        const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        const rootHash = merkleTree.getRoot().toString('hex');
        // console.log(`stakersArray Merkle root: 0x${rootHash}`);
        ownerProofDropRef.current = merkleTree.getHexProof(keccak256(accountRef.current));
        console.log('data processed');

        //console.log(`ownerProofDropRef: ${ownerProofDropRef.current}`);
        
        if (ownerProofDropRef.current.length > 0) {
          setIsEligible(true);
        } else {
          setIsEligible(false);
        }

        return;
      } else {
        setButtonText('FAILED. REFRESH.')
        console.error('Failed to fetch stakersArray:', response.status, response.statusText);
      }
    } catch (error) {
      setButtonText('FAILED. REFRESH.')
      console.error('Error fetching stakersArray:', error);
    }
  };  

  
  useEffect(() => {
    if (account && isConnected) {
    setButtonText("VERIFYING")
    verifyclaim();
    }
  }, [isConnected, isEligible]);

  useEffect(() => {
    if (airdropState !== null) {
    verify();
    console.log("useEffect Airdrop");
   }
  });

  useEffect(() => {
    if (memedropState !== null) {
    calldata();
    console.log("useEffect Memedrop");
    }
  });
  
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("Account Changed", accounts);
        if (accounts) {
          setAccount(accounts[0]);
        }
      };
  
      const handleDisconnect = () => {
        console.log("Disconnect");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        refreshState();
      };

      const handleNetworkChange = () => {
        console.log("Network Changed");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        refreshState();
      };
  
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("disconnect", handleDisconnect);
      provider.on("chainChanged", handleNetworkChange);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("disconnect", handleDisconnect);
          provider.removeListener("chainChanged", handleNetworkChange);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    const condition5 = state === false && isConnected && isEligible && airdropState;
    const condition6 = state && isConnected && isEligible && memedropState;
    const condition7 = state && isConnected && memedropState === false && isEligible;
    const condition8 = state === false && isConnected && isEligible && airdropState === false;
    const condition1 = !isConnected && state === false && isEligible === null;
    const condition2 = !isConnected && state && isEligible === null;
    const condition3 = isConnected && state === false && isEligible === false;
    const condition4 = isConnected && state && isEligible === false;

    const renders = [
      { condition: condition1, render: 
      <>
        <div className={`container ${isLoading ? 'loading-cursor' : ''}`}>
          <div className='icon'>🎁 🐸 🎁</div>
          <div className='largetxt'>VERIFY IF YOU ARE</div>
          <div className='largetxt'>ELIGIBLE FOR THE</div>
          <div className='largetxt'>AIRDROP</div>
          <div className='button1' onClick={initConnection}>{buttonText}</div>
        </div>
      </>
      },
      { condition: condition2, render: 
        <>
          <div className={`container ${isLoading ? 'loading-cursor' : ''}`}>
            <div className='icon'>🎁 🐸 🎁</div>
            <div className='largetxt'>VERIFY IF YOU ARE</div>
            <div className='largetxt'>ELIGIBLE FOR OUR</div>
            <div className='largetxt'>MEMEDROP</div>
            <div className='button1' onClick={initConnection}>{buttonText}</div>
          </div>
        </>
      },
      { condition: condition3, render: 
        <>
          <div className="container">
            <div className='icon'>💔 🐸 💔</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS <span className="gradient-text">NOT ELIGIBLE</span></div>
            <div className='largetxt'>FOR THE AIRDROP</div>
            <div className='button1' onClick={disconnectWallet} >DISCONNECT WALLET</div>
          </div>
          <div className='containerHelp'>
            <div className='mediumtxt'>Dang, you missed <span className="gradient-text">$PULSEDROP</span></div>
            <div className='mediumtxt'>Stake<span style={{ color: 'skyblue' }}> $DROP</span> today and don't miss</div>
            <div className='mediumtxt'>our next <span className="gradient-text">memedrop</span> on <span style={{ color: 'lime'}}>ZkSyncEra</span></div>
            <div className='mediumtxt'>
              <a href="https://app.camelot.exchange/?token2=0x09FD3D6e6889940Ca1158B9221309Bd69faFa32b" style={{ color: 'skyblue'}} >Buy $DROP</a>
            </div>
            <div className='mediumtxt'>
              <a href="https://stake.dropnation.wtf/" style={{ color: 'goldenrod'}} >Stake $DROP</a>
            </div>
          </div>
        </>
      },
      { condition: condition4, render: 
        <>
          <div className="container">
            <div className='icon'>💔 🐸 💔</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS <span className="gradient-text">NOT ELIGIBLE</span></div>
            <div className='largetxt'>FOR OUR MEMEDROP</div>
            <div className='button1' onClick={disconnectWallet} >DISCONNECT WALLET</div>
          </div>
          <div className='containerHelp'>
            <div className='mediumtxt'>Dang, you missed <span className="gradient-text">$PULSEDROP</span></div>
            <div className='mediumtxt'>Stake<span style={{ color: 'skyblue' }}> $DROP</span> today and don't miss</div>
            <div className='mediumtxt'>our next <span className="gradient-text">memedrop</span> on <span style={{ color: 'lime'}}>ZkSyncEra</span></div>
            <div className='mediumtxt'>
              <a href="https://app.camelot.exchange/?token2=0x09FD3D6e6889940Ca1158B9221309Bd69faFa32b" style={{ color: 'skyblue'}} >Buy $DROP</a>
            </div>
            <div className='mediumtxt'>
              <a href="https://stake.dropnation.wtf/" style={{ color: 'goldenrod'}} >Stake $DROP</a>
            </div>
          </div>
        </>
      },
      { condition: condition5, render: 
        <>
          <div className="container">
            <div className='icon'>🎉 🐸 🎉</div>
            <div className='largetxt'>GREAT!</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS ELIGIBLE</div>
            <div className='largetxt'>FOR THE AIRDROP</div>
            <div className='button1' style={{cursor: "initial"}}>ALREADY CLAIMED</div>   
          </div>
          <Fireworks options={{ opacity: 0.5, particles: 50, delay: { min: 50, max: 80}, hue: { min: 120, max: 150 }, lineStyle: 'round' }}
          style={{top: 0, left: 0, width: '100%', height: '100%', position: 'fixed', background: '#000', opacity: '0.51' }} />
        </>
      },
      { condition: condition6, render: 
        <>
          <div className="container">
            <div className='icon'>🎉 🐸 🎉</div>
            <div className='largetxt'>GREAT!</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS ELIGIBLE</div>
            <div className='largetxt'>FOR OUR MEMEDROP</div>
            <div className='button1' style={{cursor: "initial"}}>ALREADY CLAIMED</div>
          </div>
          <Fireworks options={{ opacity: 0.5, particles: 50, delay: { min: 50, max: 80}, hue: { min: 120, max: 150 }, lineStyle: 'round' }}
          style={{top: 0, left: 0, width: '100%', height: '100%', position: 'fixed', background: '#000', opacity: '0.51' }} />
        </>
      },
      { condition: condition7, render: 
        <>
          <div className="container">
            <div className='icon'>🎉 🐸 🎉</div>
            <div className='largetxt'>GREAT!</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS ELIGIBLE</div>
            <div className='largetxt'>FOR OUR MEMEDROP</div>
            <div className='button1' onClick={claimMemedrop}>CLAIM PULSEDROP</div>
          </div>
          <Fireworks options={{ opacity: 0.5, particles: 50, delay: { min: 50, max: 80}, hue: { min: 120, max: 150 }, lineStyle: 'round' }}
          style={{top: 0, left: 0, width: '100%', height: '100%', position: 'fixed', background: '#000', opacity: '0.51' }} />
        </>
      },
      { condition: condition8, render: 
        <>
          <div className="container">
            <div className='icon'>🎉 🐸 🎉</div>
            <div className='largetxt'>GREAT!</div>
            <div className='largetxt'>YOUR WALLET</div>
            <div className='largetxt' style={{ color: 'lime' }}>{truncateAddress(accountRef.current)}</div>
            <div className='largetxt'>IS ELIGIBLE</div>
            <div className='largetxt'>FOR THE AIRDROP</div>            
            <div className='button1' onClick={claimAirdrop}>CLAIM PULSEDROP</div> 
          </div>
          <Fireworks options={{ opacity: 0.5, particles: 50, delay: { min: 50, max: 80}, hue: { min: 120, max: 150 }, lineStyle: 'round' }}
          style={{top: 0, left: 0, width: '100%', height: '100%', position: 'fixed', background: '#000', opacity: '0.51' }} />
        </>
      },
    ];

    const matchedRender = renders.find((item) => item.condition);
    const componentToRender = matchedRender ? matchedRender.render : 
    <div className="container" align="center" style={{justifyContent: "center", alignItems: "center"}}>      
      <img src={require("../assets/images/exercise-cat.gif")} width="250px" style={{opacity: "0.85"}}/> 
      <div className='button1'>{buttonText}</div>      
    </div>;

    setRenderedComponent(componentToRender);
  }, [isConnected, state, isEligible]);

return (
    <s.Screen>
      <div className="switchbox">
      <div className='switchbutton gradient-text-rv' onClick={handleAirdrop}>AIRDROP</div>      
      <div className='switchbutton2 gradient-text' onClick={handleMemedrop}>MEMEDROP</div>
      </div>
      {renderedComponent}
 
      <Footer/>
    </s.Screen>
  )
}

export default Claim;